import { ERC20Token, WETH9, ChainId, WNATIVE, Native } from '@pancakeswap/sdk';
import { chains } from '@icecreamswap/constants';

// src/common.ts
var USD = chains.reduce((acc, chain) => {
  if (!chain.stableToken)
    return acc;
  return { ...acc, [chain.id]: new ERC20Token(
    chain.id,
    chain.stableToken.address,
    chain.stableToken.decimals,
    chain.stableToken.symbol,
    chain.stableToken.name
  ) };
}, {});
var STABLE_COIN = USD;
var ICE = chains.reduce((acc, chain) => {
  if (!chain.whaleAddress)
    return acc;
  return { ...acc, [chain.id]: new ERC20Token(
    chain.id,
    chain.whaleAddress,
    18,
    "WHALEC",
    "WhaleChain Token",
    "https://whalechain.live"
  ) };
}, {});

// ../utils/enumValues.ts
function* enumValues(enumObj) {
  let isStringEnum = true;
  for (const property in enumObj) {
    if (typeof enumObj[property] === "number") {
      isStringEnum = false;
      break;
    }
  }
  for (const property in enumObj) {
    if (isStringEnum || typeof enumObj[property] === "number") {
      yield enumObj[property];
    }
  }
}

// src/helpers.ts
var createEmptyList = () => {
  const list = {};
  for (const chainId of enumValues(ChainId)) {
    list[chainId] = {};
  }
  return list;
};
var EMPTY_LIST = createEmptyList();
function serializeTokens(unserializedTokens) {
  const serializedTokens = Object.keys(unserializedTokens).reduce((accum, key) => {
    return { ...accum, [key]: unserializedTokens[key].serialize };
  }, {});
  return serializedTokens;
}
function unwrappedToken(token) {
  if (token && token.equals(WNATIVE[token.chainId]))
    return Native.onChain(token.chainId);
  return token;
}
var coreTokens = {
  wcore: WETH9[ChainId.CORE],
  usdt: new ERC20Token(ChainId.CORE, "0x1eF79C3Db9B2d8b9375276B3b97fB5D23fbc9857", 18, "USDTw", "Tether USD (WHALE Bridge)"),
  eth: new ERC20Token(ChainId.CORE, "0x70ce89e071fEA0dee1517437BA41C30e9694CF99", 18, "ETHw", "Ether (WHALE Bridge)"),
  bnb: new ERC20Token(ChainId.CORE, "0x22cf83A50AA752947C9f5d69789f46e1BaA8F048", 18, "BNBw", "BNB (WHALE Bridge)")
};

// src/index.ts
var bscTokens = {};

export { EMPTY_LIST, ICE, STABLE_COIN, USD, bscTokens, coreTokens, serializeTokens, unwrappedToken };
